import { ref, computed } from 'vue'
import fetch from '@/plugin/axios'

export let userInfo = ref({})

export const isLogin = computed(() => {
  return localStorage.token && userInfo.value.id
})

export function handleRefreshToken() {
  if (!localStorage.token) {
    userInfo.value = {}
    return
  }
  return fetch.get('/account/refreshToken').then(res => {
    localStorage.token = res.token
    userInfo.value = res.userInfo
    return res
  })
}
export function handleLogoutByExpire() {
  userInfo.value = {}
  localStorage.removeItem('token')
  window.location.reload('/')
}
