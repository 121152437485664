<template lang="pug">
.imageList
  .item(v-for="(i,n) in imageList" draggable="true" @dragstart="onDragStart($event,n)" @dragend="onDragEnd($event,n)" @dragover="onDragOver($event,n)" :class="{isDrag:currentDragItemIndex===n,isDragOver:currentDragOverItemIndex===n&&currentDragItemIndex!==n}")
    .img(v-bg.cover="i")
    .actionBar
      el-button(v-if="isShowEditBtn" size="mini" circle type="primary" icon="el-icon-edit" @click="handleEditItem(n)")
      el-button(size="mini" circle type="danger" icon="el-icon-delete" @click="handleDeleteItem(n)")
  .item.addActionItem(@click.prevent="handleAddImage")
    i.el-icon-plus

</template>

<script setup>
import { inject, onMounted, ref, watch } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { pickImageList } from '@/provider/upload'

const props = defineProps({
  modelValue: {
    type: Array,
    default: []
  },
  type: {
    type: String,
    default: 'default'
  },
  isShowEditBtn: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['update:modelValue', 'onEdit'])

const imageList = ref([])

watch(
  () => props.modelValue,
  () => {
    imageList.value = props.modelValue
  }
)

let currentDragItemIndex = ref(-1)
let currentDragOverItemIndex = ref(-1)
function onDragStart(e, n) {
  currentDragItemIndex.value = n
}
function onDragEnd(e, n) {
  const sourceItem = imageList.value[currentDragItemIndex.value]
  imageList.value.splice(currentDragItemIndex.value, 1)
  if (currentDragOverItemIndex.value === 0) {
    imageList.value.unshift(sourceItem)
  } else if (currentDragOverItemIndex.value > currentDragItemIndex.value) {
    imageList.value.splice(currentDragOverItemIndex.value - 1, 0, sourceItem)
  } else {
    imageList.value.splice(currentDragOverItemIndex.value, 0, sourceItem)
  }
  currentDragItemIndex.value = -1
  currentDragOverItemIndex.value = -1
  updateValue()
}
function onDragOver(e, n) {
  currentDragOverItemIndex.value = n
}

function updateValue() {
  emits('update:modelValue', imageList.value)
}

function handleEditItem(n) {
  emits('onEdit', n)
}
function handleDeleteItem(n) {
  imageList.value.splice(n, 1)
  updateValue()
}
function handleAddImage() {
  pickImageList({ count: 9, type: props.type || 'default' }).then(res => {
    if (res?.length) {
      res.forEach(url => {
        const n = imageList.value.findIndex(i => i === url)
        if (n < 0) {
          imageList.value.push(url)
        }
      })
      updateValue()
    }
  })
}

onMounted(() => {
  imageList.value = props.modelValue
})
</script>

<style lang="less" scoped>
.imageList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  * {
    transition: all 0.2s ease;
  }
  .item {
    width: 400px;
    height: 200px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: move;
    &:hover {
      border: 1px solid var(--color-primary);
    }

    .img {
      width: 200px;
      height: 200px;
    }
    .actionBar {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: space-around;
      padding: 5px;
    }
    &.addActionItem {
      font-size: 60px;
      cursor: pointer;
      &:hover {
        color: var(--color-success);
        border: 1px solid var(--color-success);
      }
    }
    &.isDrag {
      opacity: 0.4;
    }
    &.isDragOver {
      overflow: unset;
      margin-left: 70px;
      &:before {
        content: '';
        display: block;
        width: 50px;
        background: var(--color-background);
        border: 1px dashed var(--color-primary);
        border-radius: 10px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -70px;
      }
    }
  }
}
</style>
