import { createRouter, createWebHistory } from 'vue-router'
import routeList from 'vue-auto-routing'
import { createRouterLayout } from 'vue-router-layout'

const RouterLayout = createRouterLayout(layout => {
  return import('@/layout/' + layout + '.vue')
})

export const routes = [
  {
    path: '/',
    component: RouterLayout,
    children: routeList
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/redirect/404'
  }
]

export function useRouter(app) {
  if (!app) return
  const routeInstance = createRouter({
    history: createWebHistory('/'),
    routes: routes
  })
  app.use(routeInstance)
}
