<template lang="pug">
.imagePickContainer
  .image(v-if="props.modelValue" v-bg.cover="props.modelValue" @click="pickImage" :style="imageStyle")
  .addImage(v-else @click="pickImage" :style="imageStyle")
    i.el-icon-upload

</template>

<script setup>
import { inject, onMounted, ref, computed } from 'vue'
import { pickImageList } from '@/provider/upload'

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'system'
  },
  width: {
    type: [Number, String],
    default: 200
  },
  height: {
    type: [Number, String],
    default: 200
  },
  size: {
    type: String,
    default: 'avatar'
  }
})

const imageStyle = computed(() => {
  if (props.size === 'avatar') {
    return {
      width: '100px',
      height: '100px',
      borderRadius: '50%'
    }
  }
  if (props.size === 'icon') {
    return {
      width: '100px',
      height: '100px',
      borderRadius: '0'
    }
  }
  if (props.size === 'poster') {
    return {
      width: '400px',
      height: '200px',
      borderRadius: '10px'
    }
  }
  return {}
})

const emits = defineEmits(['update:modelValue'])

function pickImage() {
  pickImageList({ type: props.type, count: 1 }).then(resultList => {
    if (!resultList.length) return
    emits('update:modelValue', resultList[0])
  })
}
</script>

<style lang="less" scoped>
.imagePickContainer {
  .image,
  .addImage {
    width: 100px;
    height: 100px;
    cursor: pointer;
    border: 1px dashed var(--color-border);
    &:hover {
      border: 1px dashed var(--color-primary);
    }
  }
  .addImage {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    border: 1px dashed var(--color-success);
    color: var(--color-success);
    cursor: pointer;
  }
}
</style>
