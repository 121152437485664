<template lang="pug">
router-view

</template>

<script setup>
import { provide, computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { loading } from '@/provider/sys'
import { handleRefreshToken, isLogin } from '@/provider/account'
import { ElMessage, ElNotification } from 'element-plus'
import fetch from '@/plugin/axios'

const router = useRouter()
const route = useRoute()

provide('global', {
  fetch,
  route,
  router,
  loading,
  message: ElMessage,
  notify: ElNotification,
  isDebug: route?.query?.debug === 'true'
})

const authWhiteList = ['/account/login']

onMounted(() => {
  if (localStorage.token) {
    handleRefreshToken().catch(err => {
      localStorage.removeItem('token')
      ElMessage.warning('登录状态失效，请重新登录')
      router.replace('/account/login')
    })
  } else if (!isLogin.value) {
    const isPathInWhiteList = authWhiteList.includes(route.path)
    if (!isPathInWhiteList) {
      ElMessage.warning('请先登陆')
      router.replace('/account/login')
    }
  }
})
</script>

<style lang="less">
@import '~@/style/global/reset.less';
@import '~@/style/global/global.less';
</style>
