<template lang="pug">
.popoverContainer
  el-popover(placement="top-start" :width="200" trigger="hover" :title="props.title" :content="props.content")
    template(#reference)
      i.questionIcon.el-icon-question
</template>

<script setup>
import { inject, ref } from 'vue'

const props = defineProps({
  title: {
    type: String,
    default: '提示'
  },
  content: {
    type: String,
    default: ''
  }
})
</script>

<style lang="less" scoped>
.popoverContainer {
  display: inline-block;
  margin: 0 10px;
}
.questionIcon {
  font-size: 20px;
  color: var(--color-green);
  vertical-align: middle;
}
</style>
