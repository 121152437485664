<template lang="pug">
.tagListContainer
  el-tag(v-for="(i,n) in list" closable @close="handleDeleteTag(n)" style="margin-right:10px;") {{ i }}
  el-input(v-if="isShowAddtagInput" v-model="addTagInputValue" size="mini" @keyup.enter="handleAddTag" @blur="handleAddTag" style="width:100px")
  el-button(v-else size="small" type="success" plain @click="isShowAddtagInput = true" icon="el-icon-plus") 添加标签

</template>

<script setup>
import { inject, onMounted, ref } from 'vue'

const props = defineProps({
  modelValue: {
    type: Array,
    default: []
  }
})

const emits = defineEmits(["update:modelValue"])

const list = ref([])

const isShowAddtagInput = ref(false)
const addTagInputValue = ref('')
function handleDeleteTag(n) {
  list.value.splice(n, 1)
  updateValue()
}
function handleAddTag() {
  if (addTagInputValue.value) {
    const n = list.value.findIndex(i => i === addTagInputValue.value)
    if (n < 0) {
      list.value.push(addTagInputValue.value)
    }
  }
  addTagInputValue.value = ''
  isShowAddtagInput.value = false
  updateValue()
}

function updateValue() {
  emits("update:modelValue", list.value)
}

onMounted(() => {
  list.value = props.modelValue
})
</script>

<style lang="less" scoped>
.page {
  width: 100%;
}
</style>
