import axios from 'axios'
import { logAxiosSuccess, logAxiosError, log } from '@/util/log'
import { handleRefreshNewToken, handleLogoutByExpire } from '@/provider/account'

const baseURL = process.env.VUE_APP_apiBaseUrl
let config = {
  baseURL: baseURL,
  timeout: 10 * 1000,
  withCredentials: false
}

const instance = axios.create(config)

instance.interceptors.request.use(
  config => {
    if (localStorage.token) {
      config.headers['x-homebrew-token'] = localStorage.token
    }
    if (config.params) {
      Reflect.deleteProperty(config.params, 'total')
      Reflect.deleteProperty(config.params, 'isFinish')
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  response => {
    const isPublicFetch = checkIsPublicFetch(response.config.url)
    if (isPublicFetch) {
      logAxiosSuccess(response)
      return response.data.data || response.data
    } else {
      if (response.headers['x-new-token']) {
        localStorage.token = response.headers['x-new-token']
      }
      if ([2001, 2002, 2003].includes(response.data.code)) {
        handleLogoutByExpire(response)
        return Promise.reject(response.data)
      } else if (response.data.code === 0 || response.data.err === 0) {
        logAxiosSuccess(response)
        return response.data.data
      } else {
        logAxiosError(response)
        return Promise.reject(response.data)
      }
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default instance

function checkIsPublicFetch(url) {
  if (url.includes('localhost')) return false
  if (url.includes('127.0.0.1')) return false
  if (!url.includes('http')) return false
  return !url.includes(baseURL)
}
