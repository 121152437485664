import { createApp } from 'vue'
import App from './App.vue'

import { isMicroApp, isDev, projectId } from '@/util/env'

import { useComponent } from '@/plugin/component'
import { useRouter } from '@/plugin/router'
import { useDirective } from '@/plugin/directive'
import { useElementPlus } from '@/plugin/element'

let app = createApp(App)

useRouter(app)
useDirective(app)
useElementPlus(app)
useComponent(app)

app.mount(`#app`)
