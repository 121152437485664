import { ref, computed } from 'vue'

export let loading = ref(false)

export const isMobile = computed(() => {
  if (window.innerWidth > 700) {
    return false
  }
  return true
})
